const MANDATORY_FIELD_MESSAGE = 'Campo obrigatório!';

import * as yup from 'yup';

export const personalDataSchema = yup.object().shape({
  email: yup
    .string()
    .email('E-mail inválido')
    .required(MANDATORY_FIELD_MESSAGE)
    .matches(/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/, 'E-mail inválido'),
  first_name: yup.string().required(MANDATORY_FIELD_MESSAGE),
  last_name: yup.string().required(MANDATORY_FIELD_MESSAGE),
  phone: yup
    .string()
    .required(MANDATORY_FIELD_MESSAGE)
    .matches(/\(\d{2}\)\s\d{4,5}-\d{4}/, {
      message: 'Telefone inválido',
    }),
  /* payment_method: yup.string().required(MANDATORY_FIELD_MESSAGE), */
});
