import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { useContact } from '@/hooks/Contact';
import { Input } from '@/components/Input';
import { Error } from '@/styles/global';
import { Container, Inputs, PhoneContainer, ProcessDescription, Terms } from './styles';
import { useFirstInteractionFields } from '@/hooks/useFirstInteractionFields';
import { personalDataSchema } from './personalDataSchema';
import { pushDataLayer } from '@/utils/dataLayer';
import axios, { AxiosError } from 'axios';
import { StyledSubmitButton } from '../SubmitButton/styles';

type FormInputs = {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  payment_method: string;
};

export default function PersonalData() {
  const [loading, setLoading] = useState(false);

  const { contact } = useContact();
  const { buildFirstInteractionFields } = useFirstInteractionFields();

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm<FormInputs>({
    resolver: yupResolver(personalDataSchema),
    mode: 'onChange',
  });

  function setPhoneValue() {
    const phoneInput = document.getElementById('phone');
    phoneInput?.setAttribute('value', contact.phone ?? '');
  }

  async function onSubmit(data: FormInputs) {
    try {
      setLoading(true);

      pushDataLayer({
        event: 'form_submit_kenzie',
        form_name: 'Formulário de Inscrição',
        payment_method: 'Fullstack À Vista',
      });

      const firstInteractionFields = await buildFirstInteractionFields();
      const newContact = { ...contact, ...data, ...firstInteractionFields };
      await axios.post('/api/subscription', newContact);

      window.location.href = `/completed_subscription?name=${data.first_name}`;
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError.response?.data.errors);
    } finally {
      setLoading(false);
    }
  }

  /*
  const paymentOptions = [
    {
      label: 'Fullstack - À Vista | R$ 2.997',
      value: 'Fullstack À Vista',
    },
    {
      label: 'Fullstack - Parcelado | 12x de R$ 297',
      value: 'Fullstack Parcelado',
    },
    {
      label: 'Front-end - À Vista | R$ 1.997',
      value: 'Front-end À Vista',
    },
    {
      label: 'Front-end - Parcelado | 12x de R$ 197',
      value: 'Front-end Parcelado',
    },
  ];
  */

  useEffect(() => {
    setValue('email', contact.email ?? '');
    setValue('first_name', contact.first_name ?? '');
    setValue('last_name', contact.last_name ?? '');
    setValue('phone', contact.phone ?? '');
    setPhoneValue();
  }, []);

  useEffect(() => {
    function handleAbandon() {
      const values = getValues();

      const newDataLayer = {
        event: 'form_submit_kenzie',
        form_name: 'Nome do formulário',
        input_user_name: !!values.first_name,
        input_user_surname: !!values.last_name,
        input_user_email: !!values.email,
        input_user_phone: !!values.phone,
        payment_method: !!values.payment_method,
      };

      pushDataLayer(newDataLayer);
    }

    window.addEventListener('beforeunload', handleAbandon);

    return () => {
      window.removeEventListener('beforeunload', handleAbandon);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <ProcessDescription>
          <h2>Entre na lista de espera para as próximas turmas!</h2>
          <p>Você está dando o primeiro passo para se tornar uma pessoa programadora!</p>
          <p>Lembre-se de preencher as informações corretamente.</p>
          {/*
               <StartOfClasses>
            <h3>50% de desconto</h3>

            <div className="flex">
              <div>
                <p className="modality">apenas este mês!</p>
              </div>
            </div>
          </StartOfClasses>
      */}
        </ProcessDescription>

        <Inputs>
          <Input
            id="firstName"
            label="Nome"
            type="text"
            placeholder="Digite aqui o seu nome"
            {...register('first_name')}
            error={errors.first_name}
          />
          <Input
            id="lastName"
            label="Sobrenome"
            type="text"
            placeholder="Digite aqui o seu sobrenome"
            {...register('last_name')}
            error={errors.last_name}
          />
          <Input
            id="email"
            label="Email"
            type="text"
            placeholder="Digite aqui o seu melhor email"
            {...register('email')}
            error={errors.email}
          />

          <PhoneContainer>
            <label htmlFor="phone">Telefone</label>
            <InputMask
              placeholder="Digite aqui o seu telefone"
              mask="(99) 99999-9999"
              type="tel"
              pattern="\([0-9]{2}\)\s9[0-9]{4}-[0-9]{4}"
              id="phone"
              {...register('phone')}
            />
            {errors.phone?.message && <Error>{errors.phone?.message}</Error>}
          </PhoneContainer>

          {/* <Select
            id="paymentCondition"
            label="Como deseja pagar o curso?"
            options={paymentOptions}
            {...register('payment_method')}
            error={errors.payment_method}
          /> */}

          <StyledSubmitButton type="submit" disabled={loading}>
            {loading ? 'Enviando...' : 'Lista de Espera'}
          </StyledSubmitButton>

          <Terms>
            Na qualidade de Usuário declaro que li, compreendi e estou de acordo com os{' '}
            <a href="https://inscricao.kenzie.com.br/termos-condicoes" target="_blank" rel="noreferrer">
              {' '}
              Termos de Uso e com a Política de Privacidade.
            </a>
          </Terms>
        </Inputs>
      </Container>
    </form>
  );
}
