import Image from 'next/image';
import logo from '@/images/logo-header.png';

import { Container, StyledHeader } from './styles';

export default function Header() {
  return (
    <StyledHeader>
      <Container>
        <h1 title="Formulário de inscrição - Kenzie Academy Brasil">
          <a href="https://kenzie.com.br/" target="_blank" rel="noreferrer">
            <Image src={logo.src} alt="Logo Kenzie Academy" width="120" height="30" unoptimized />
          </a>
        </h1>
      </Container>
    </StyledHeader>
  );
}
