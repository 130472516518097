import { getCookie, setCookie } from '@/hooks/useCookies';

const firstInteractionFieldList: Array<string> = [
  'utm_source_first',
  'utm_medium_first',
  'utm_campaign_first',
  'utm_content_first',
  'utm_term_first',
  'has_src_first',
  'has_cam_first',
  'has_grp_first',
  'has_ad_first',
];

export const useFirstInteractionFields = () => {
  return {
    setFirstInteractionInCookies: (params: URLSearchParams) => {
      firstInteractionFieldList.forEach((field: string) => {
        const cookieValue = getCookie(field);
        const paramValue = params.get(field.replace('_first', ''));

        if (cookieValue === undefined && paramValue !== null)
          setCookie({ name: field, value: paramValue as string });
      });
    },
    buildFirstInteractionFields: async () =>
      firstInteractionFieldList
        .map(field => ({
          [field]: getCookie(field) ?? 'no-tracking',
        }))
        .reduce((acc, current) => ({ ...acc, ...current }), {}),
  };
};
