import styled from 'styled-components';

export const Background = styled.main`
  background: linear-gradient(131.31deg, #f5f5f5 3.65%, #92b7fb 100%);
`;

export const Content = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  padding: 3.5rem 0;
  width: 90%;

  @media (min-width: 360px) {
    width: 85%;
  }

  @media (min-width: 1024px) {
    padding: 5rem 0;
  }
`;
