import Head from 'next/head';
import { useSearchParams } from 'next/navigation';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { useFirstInteractionFields } from '@/hooks/useFirstInteractionFields';
import { Background, Content } from '@/styles/pages/home';
import PersonalData from '@/components/PersonalData';

export default function Home() {
  const searchParams = useSearchParams();
  const { setFirstInteractionInCookies } = useFirstInteractionFields();

  if (typeof document !== 'undefined') setFirstInteractionInCookies(searchParams);
  return (
    <>
      <Head>
        <title>Formulário de inscrição - Kenzie Academy Brasil</title>
        <link rel="canonical" href="https://inscricao.kenzie.com.br/" />
        <meta
          name="description"
          content="Faça o Curso de Programação da Kenzie, entre no mercado rapidamente e ganhe altos salários!"
        />
        <meta name="twitter:title" content="Kenzie Academy Brasil: Curso de Programação Online" />
        <meta
          name="twitter:description"
          content="Faça o Curso de Programação da Kenzie, entre no mercado rapidamente e ganhe altos salários!"
        />
        <meta property="og:title" content="Kenzie Academy Brasil: Curso de Programação Online" />
      </Head>
      <Header />
      <Background>
        <Content>
          <PersonalData />
        </Content>
      </Background>
      <Footer bgColor="#000" />
    </>
  );
}
