import styled from 'styled-components';

export const StyledSubmitButton = styled.button`
  font-family: 'JetBrains Mono', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1rem 1.5rem;
  border-radius: 90px;
  background: var(--highlight-blue);
  height: 2.813rem;
  width: 12.438rem;
  display: block;
  color: #fff;
  transition: all 0.3s ease-in-out;
  margin-top: 1.5rem;
  cursor: pointer;
  margin: 1.5rem auto auto;

  &:hover,
  &:focus {
    box-shadow: 0 0 16px 4px var(--highlight-blue);
  }

  @media (min-width: 1024px) {
    height: 4.25rem;
    width: 14.25rem;
    font-size: 1.125rem;
  }
`;
