import styled from 'styled-components';

export const StyledInputContainer = styled.div`
  margin-top: 1.5rem;
  width: 100%;

  label {
    font-family: 'Open Sans', sans-serif;
    display: block;
    margin: 0.313rem 0 0.313rem 1rem;
    color: var(--text-color);
    font-weight: 700;
    font-size: 1rem;
  }

  input {
    width: 100%;
    padding: 1.25rem 0.625rem;
    border: 1px solid #a49f9f;
    border-radius: 5px;
    outline: none;
    color: #909090;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    background: #fff;

    &::placeholder {
      font-size: 1.125rem;
      color: #909090;
    }

    &:focus {
      border: 2px solid var(--highlight-blue);
    }
  }

  @media (min-width: 1024px) {
    label {
      font-size: 1.125rem;
    }
  }
`;
