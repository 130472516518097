import styled from 'styled-components';

export const StyledHeader = styled.header`
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  height: 86px;
  background-color: var(--highlight-blue);
  transition: background, border 0.3s ease;

  img {
    object-fit: contain;

    @media (min-width: 768px) {
      height: 45px;
      width: 181px;
    }
  }

  @media (min-width: 1024px) {
    height: 82px;
  }
`;

export const Container = styled.div`
  max-width: var(--max-width);
  width: 85%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 360px) {
    width: 70%;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
  }

  @media (min-width: 1600px) {
    width: 50%;
  }
`;
