import styled from 'styled-components';

export const Container = styled.div`
  margin: 1.875rem auto;
  display: flex;
  flex-direction: column;
  max-width: 24.688rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    max-width: 69.625rem;
    background: var(--text-color-secondary);
    margin: 2rem auto;
    border-radius: 20px;
  }
`;

export const ProcessDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  padding: 1.875rem;
  background: var(--highlight-blue);
  border-radius: 20px;

  h2 {
    font-size: 1.375rem;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    color: var(--highlight-yellow);
  }

  p {
    color: var(--text-color-secondary);
    text-align: center;
  }

  @media (min-width: 1024px) {
    max-width: 32.188rem;
    padding: 2.375rem 4.375rem;

    h2 {
      text-align: initial;
      font-size: 1.875rem;
    }

    p {
      font-size: 1.125rem;
      text-align: initial;
      max-width: 18.75rem;
      align-self: flex-start;
    }
  }
`;

export const StartOfClasses = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  border-radius: 14px;
  border: 1px solid var(--text-color-secondary);
  width: 100%;
  padding: 1.25rem 0.35rem;

  h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.125rem;
    color: var(--highlight-yellow);
    text-align: center;
  }

  .flex {
    display: flex;
    margin: 0 auto;
    gap: 1.25rem;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.2rem;

      p {
        &.modality {
          text-align: center;
          font-weight: 700;
        }

        &.date {
          margin: 0 auto;
        }
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media (min-width: 1024px) {
    margin-top: 1rem;
    h3 {
      font-size: 1.25rem;
    }

    .flex {
      div {
        svg {
          margin-bottom: 0.5rem;
        }
        p {
          &.modality {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
`;

export const Inputs = styled.div`
  @media (min-width: 1024px) {
    width: 100%;
    padding: 1.875rem 2.5rem;
  }
`;

export const PhoneContainer = styled.div`
  min-width: 49%;
  margin-top: 1.5rem;

  label {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    display: block;
    margin: 0.313rem 0 0.313rem 1rem;
    color: var(--text-color);
    font-weight: 700;
  }

  input {
    width: 100%;
    padding: 1.22rem 0.625rem;
    border: 1px solid #a49f9f;
    border-radius: 5px;
    outline: none;
    color: #909090;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    background: #fff;
    font-family: 'Open Sans', sans-serif;

    &::placeholder {
      font-size: 1.125rem;
      color: #909090;
    }

    &:focus {
      border: 2px solid var(--highlight-blue);
    }
  }

  @media (min-width: 1024px) {
    label {
      font-size: 1.125rem;
    }
  }
`;

export const Terms = styled.div`
  font-size: 0.6rem;
  font-family: 'Open Sans', sans-serif;
  margin: 1.5rem auto 0;

  align-self: center;

  a {
    font-size: 0.6rem;
    text-decoration: underline;
  }

  @media (min-width: 1024px) {
    font-size: 0.8rem;
    align-self: center;
    padding: 0 0.7rem;

    a {
      font-size: 0.8rem;
    }
  }
`;
