import { forwardRef, PropsWithoutRef } from 'react';
import { FieldError } from 'react-hook-form';
import { Error } from '@/styles/global';

import { StyledInputContainer } from './styles';

type InputProps = PropsWithoutRef<JSX.IntrinsicElements['input']> & {
  error?: FieldError;
  label: string;
  type: 'text' | 'date';
};

// eslint-disable-next-line react/display-name
export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ error, label, type, id, ...rest }, ref) => {
    return (
      <StyledInputContainer>
        <label htmlFor={id}>{label}</label>
        <input id={id} type={type} ref={ref} {...rest} />
        {error?.message && <Error>{error.message}</Error>}
      </StyledInputContainer>
    );
  }
);
